import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
//imported
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { useCustomHistory } from "helpers/getHistory";
import { MAIN_LOGO, MAIN_LOGO_DUMMY_ } from "utils/constant";
//services
import { logout, profile_ } from "store/services/authService";

const Header = ({ user, dispatch, userProfile, sidebarHideShow }) => {
  //history
  const history = useHistory();

  let userInfo = userProfile?.profileImage
    ? userProfile.profileImage
    : user?.user?.profileImage
    ? user?.user?.profileImage
    : MAIN_LOGO_DUMMY_;
  const path = useCustomHistory();
  const [isActiveClass, setIsActiveClass] = useState(false);
  //logout function
  const handleLogout = async () => {
    try {
      const authToken = user && user.user ? user.user.token : "";
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(logout(authToken)).then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(actions.persist_store({ loader: false }));
        dispatch(actions.persist_store({ userProfile: null }));
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  async function getData() {
    try {
      let res = await dispatch(profile_());
      dispatch(actions.persist_store({ userProfile: res?.data }));
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (!userProfile && user && user.user && user.user.token) {
      getData();
    }
  }, []);

  const addDropdownClass = () => {
    setIsActiveClass(!isActiveClass);
  };
  return (
    <>
      <nav
        className={`navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row`}
      >
        <div className="navbar-menu-wrapper d-flex align-items-stretch w-100">
          {/* <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize" onClick={sidebarHideShow}>
            <span className="mdi mdi-menu"></span>
          </button> */}
          <div className="d-flex align-items-center justify-content-center">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
              <a className="navbar-brand brand-logo" href="#">
                <img src={MAIN_LOGO} alt="logo" style={{ height: "60px" }} />
              </a>
            </div>
            <div className="font-weight-bold display-10 ms-5">
              DEVIDEN USERS OVERVIEW
            </div>
          </div>

          <ul className="navbar-nav navbar-nav-right">
            <li
              className={`nav-item nav-profile dropdown ${
                isActiveClass ? "show" : ""
              }`}
              onClick={addDropdownClass}
            >
              <a
                className="nav-link dropdown-toggle"
                id="profileDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-profile-img">
                  {/* <img style={{width:'40px', height:'35px'}} src={MAIN_LOGO_DUMMY_} alt="image" /> */}
                  <img
                    style={{ width: "40px", height: "35px" }}
                    src={`${userInfo}`}
                    alt="image"
                  />
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-dark fw-bold">
                    {userProfile?.name ? userProfile?.name : user?.user?.name}
                  </p>
                </div>
              </a>
              <div
                className={`dropdown-menu navbar-dropdown ${
                  isActiveClass ? "show" : ""
                }`}
                aria-labelledby="profileDropdown"
              >
                <Link className="dropdown-item" to={Path.profile}>
                  <i className="mdi mdi-account me-2"></i> Profile{" "}
                </Link>
                <Link className="dropdown-item" to={Path.change_password}>
                  <i className="mdi mdi-lock-open me-2"></i> Change Password{" "}
                </Link>
                <a className="dropdown-item" href="#" onClick={handleLogout}>
                  <i className="mdi mdi-logout me-2"></i> Signout{" "}
                </a>
              </div>
            </li>
            <li
              className="nav-item nav-logout d-none d-lg-block"
              onClick={handleLogout}
            >
              <a className="nav-link" href="#">
                <i className="mdi mdi-power"></i>
              </a>
            </li>
          </ul>
          {/* <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
            onClick={sidebarHideShow}
          >
            <span className="mdi mdi-menu"></span>
          </button> */}
        </div>
      </nav>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    loader: state.persistStore.loader,
    user: state.Auth,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Header);
