// const moment = require('moment');
import moment from "moment-timezone";

export function FORMAT_TEXT(text) {
  const text_ = text?.length > 20 ? text?.substring(0, 20) + "..." : text;
  return text_;
}
export function FORMAT_TEXT_(text) {
  const text_ = text?.length > 50 ? text?.substring(0, 50) + "..." : text;
  return text_;
}
export function FORMAT_TEXT_VALUE(text) {
  const text_ = text
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  return text_;
}

export function DATE_FORMAT_FOR_DB(val) {
  // Check if the input value is valid
  if (!val) {
    return "invalid"; // Return "invalid" if the value is not provided
  }

  // Parse the original date string using Moment.js
  let originalDate = moment(val, "YYYY-MM-DD hh:mm A");

  // Check if the date is valid
  if (!originalDate.isValid()) {
    return "invalid"; // Return "invalid" if the date is not valid
  }

  // Format the date string in the desired format
  let newDateString = originalDate.format("YYYY-MM-DDTHH:mm");
  return newDateString;
}

export function DATE_FORMAT_FOR_DB_(val) {
  // Check if the input value is valid
  if (!val) {
    return "invalid"; // Return "invalid" if the value is not provided
  }

  // Parse the original date string using Moment.js
  let originalDate = moment(val, "DD/MM/YYYY");

  // Check if the date is valid
  if (!originalDate.isValid()) {
    return "invalid"; // Return "invalid" if the date is not valid
  }

  // Format the date string in the desired format
  let newDateString = originalDate.format("DD/MM/YYYY");
  return newDateString;
}

export function isBase64(str) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export function DATE_FORMAT(inputDate) {
  const formattedDate = moment(inputDate).tz("UTC").format("MMM D, YYYY");
  return formattedDate;
}

export function DATE_FORMAT_TIME(inputDate) {
  const formattedDate = moment(inputDate)
    .tz("UTC")
    .format("MMM D, YYYY hh:mm a");
  return formattedDate;
}
export function DATE_FORMAT_TIME_(inputDate) {
  const formattedDate = moment(inputDate).format("MMM D, YYYY hh:mm a");
  return formattedDate;
}
export function DATE_FORMAT_TIME_NOTES(inputDate) {
  const formattedDate = moment(inputDate).format("MMM D, YYYY");
  return formattedDate;
}

export function tradesDatacsv(data) {
  let arr = [];
  for (let val of data) {
    let obj = {
      "Client Name": val?.userDetails?.first_name,
      "Client Last Name": val?.userDetails?.last_name,
      "Account Id": val?.userDetails?.accountId,
      Balance: val?.userDetails?.walletCredit,
      Equity: val?.userDetails?.equity,
      "Stock Name": val?.name,
      Price: val?.price,
      Symbol: val?.symbol,
      "Bought Price": val?.boughtPrice,
      "Current Price": val?.current_price,
      PLPC: val?.unrealized_plpc,
      Qty: val?.qty,
    };
    arr.push(obj);
  }
  return arr;
}

export function tradesSchduleDatacsv(data) {
  let arr = [];
  for (let val of data) {
    let obj = {
      "Client Name": val?.userDetails?.first_name,
      "Client Last Name": val?.userDetails?.last_name,
      "Account Id": val?.userDetails?.accountId,
      "Stock Name": val?.stockName,
      Commission: val?.commission,
      "Stock Price": val?.stockPrice,
      "Order Price": val?.orderPrice,
      Symbol: val?.symbol,
      "Scheduled Type": val?.scheduledType,
      Status: val?.status,
      Qty: val?.qty,
    };
    arr.push(obj);
  }
  return arr;
}

export function usersDatacsv(data) {
  let arr = [];
  for (let val of data) {
    let obj = {
      Name: val?.first_name + " " + val?.last_name,
      "Registration Date": DATE_FORMAT_TIME_(val?.createdAt),
      Status: val.status,
      Deposits: val?.depositCommission,
      Withdrawals: val?.withdrawCommission,
    };
    arr.push(obj);
  }
  return arr;
}

export function paymentDatacsv(data) {
  let arr = [];
  for (let val of data) {
    let obj = {
      Customer: `${val?.userDetails?.first_name} ${val?.userDetails?.last_name}`,
      "Transaction Id": val?.transactionId,
      "Date & Time": DATE_FORMAT_TIME_(val?.createdAt),
      "Transaction Amount": val?.amount,
      Type: val?.transactionType,
      Status: val?.status,
    };
    arr.push(obj);
  }
  return arr;
}

export function taskDatacsv(data) {
  let arr = [];
  for (let val of data) {
    let obj = {
      Subject: val?.subjectDetails?.name,
      Assigned: val?.assignDetails?.name,
      Title: val?.title,
      Comment: val?.comment,
      Expire: DATE_FORMAT_TIME(val?.endDate),
      Status: val?.status,
    };
    arr.push(obj);
  }
  return arr;
}

export function lgogsDatacsv(data) {
  let arr = [];
  for (let val of data) {
    let obj = {
      "Client Name": val?.updateData?.name,
      Type: val?.type,
      "Created At": DATE_FORMAT_TIME(val?.createdAt),
      Logs: val?.text,
    };
    arr.push(obj);
  }
  return arr;
}
