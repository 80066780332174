import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
//imported
import { BANK_REQUEST, PAGE_LIMIT } from "helpers/viewContent";
import { DELETE_ALERT } from "helpers/constantsMessage";
import { MAIN_DUMMY_ } from "utils/constant";
import ReactPagination from "components/shared/ReactPagination";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import SearchFilter from "components/shared/SearchFilter";
import { DATE_FORMAT_TIME, FORMAT_TEXT } from "helpers/common";
import RequestModal from "./RequestModal";
//services
import {
  getList_,
  deleteData_,
  updateData_,
} from "store/services/commonService";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  loader,
  userProfile,
  user,
}) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);
  //show modal
  const [show, setShow] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);

  const handleClose = () => {
    setShow(false);
    setCurrentValue(null);
  };
  const handleShow = (list) => {
    setShow(true);
    setCurrentValue(list);
  };
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    order: 1,
    orderBy: "createdAt",
  });
  //listing data
  const [listing, setListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [disabledButtonsCancel, setDisabledButtonsCancel] = useState([]);

  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list.name.toLowerCase();
      return itemName.includes(searchTerm);
    });
  }

  //get data
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "getUserBankAccountList",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
  };

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const selectSearchOption = [
    {
      id: "1",
      value: "active",
      name: "Active",
    },
    {
      id: "2",
      value: "inactive",
      name: "Inactive",
    },
  ];

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  //approve reject button
  const approveTransaction = async (data, status, index) => {
    try {
      // Create a new array to update disabledButtons state
      const updatedDisabledButtons = [...disabledButtons];
      // Disable the button at the specified index
      updatedDisabledButtons[index] = true;
      // Update the state to disable the button
      setDisabledButtons(updatedDisabledButtons);

      let query = {
        url: "approveOrRejectBankRequest",
        bankId: data?._id,
        status: status,
      };
      let res = await dispatch(updateData_(query));
      updatedDisabledButtons[index] = false;
      setDisabledButtons(updatedDisabledButtons);
      fetchData();
      // setIsSubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };
  const RejectTransaction = async (data, status, index) => {
    try {
      // Create a new array to update disabledButtons state
      const updatedDisabledButtons = [...disabledButtonsCancel];
      // Disable the button at the specified index
      updatedDisabledButtons[index] = true;
      // Update the state to disable the button
      setDisabledButtonsCancel(updatedDisabledButtons);

      let query = {
        url: "approveOrRejectBankRequest",
        bankId: data?._id,
        status: status,
      };
      let res = await dispatch(updateData_(query));
      updatedDisabledButtons[index] = false;
      setDisabledButtonsCancel(updatedDisabledButtons);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };
  //render html
  const renderList_ = () => {
    return (
      listToDisplay?.length > 0 &&
      listToDisplay.map((list, i) => (
        <tr
          key={i}
          onClick={() => handleShow(list)}
          className="cursor-pointer"
          title="View"
        >
          <td>
            {list?.accountHolderName
              ? FORMAT_TEXT(list?.accountHolderName)
              : ""}
          </td>
          <td>{list?.nickname ? FORMAT_TEXT(list?.nickname) : ""}</td>
          <td>
            {list?.userDetails?.email
              ? FORMAT_TEXT(list?.userDetails?.email)
              : "N/A"}
          </td>
          <td>
            {" "}
            <label className="badge badge-success payment_status">
              {list?.status ? list?.status : "N/A"}
            </label>
          </td>
          <td>
            {list?.status && list?.status === "pending" ? (
              <>
                <button
                  type="submit"
                  onClick={() => approveTransaction(list, "approved", i)}
                  className="btn btn-sm btn-gradient-primary"
                  title="Incomplete"
                  disabled={disabledButtons[i]}
                >
                  Approve
                </button>
                &nbsp; &nbsp;
                <button
                  type="submit"
                  onClick={() => RejectTransaction(list, "rejected", i)}
                  className="btn btn-sm btn-gradient-primary"
                  title="Incomplete"
                  disabled={disabledButtonsCancel[i]}
                >
                  Reject
                </button>
              </>
            ) : (
              <>
                <label className="badge badge-success payment_status">
                  {list?.status ? list?.status : "N/A"}
                </label>
              </>
            )}
          </td>
        </tr>
      ))
    );
  };

  return (
    <>
      <Helmet title={BANK_REQUEST.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{BANK_REQUEST.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.promotion}>{BANK_REQUEST.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <div className="filter_name">Listing</div>
              </h4>
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th
                        onClick={() => handleSortingFunc("accountHolderName")}
                      >
                        Account Holder
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "accountHolderName"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "accountHolderName"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("nickname")}>
                        Nickname
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "nickname"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "nickname"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("email")}>
                        email{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "email"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "email"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderList_()}</tbody>
                </table>
              </div>
              {listing.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing?.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <RequestModal
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        dispatch={dispatch}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
