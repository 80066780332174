export const PAGE_LIMIT = 20; // set 50
export const PAGE_LIMIT_ = 10; // set 50
export const itemsPerPage = 10;
export const LOGIN = {
  CURRENT_MODULE: "Login",
  TOP_CONTENT: "WELCOME BACK !",
  MAIN_CONTENT: "Sign in to continue zenaura",
  BUTTON: "SIGN IN",
};
export const FORGOT_PASSWORD = {
  CURRENT_MODULE: "Forgot Password",
  TOP_CONTENT: "WELCOME BACK !",
  MAIN_CONTENT: "We will send a link to reset your password",
  BUTTON: "Forgot Password",
};
export const RESET_PASSWORD = {
  CURRENT_MODULE: "Reset Password",
  TOP_CONTENT: "WELCOME BACK !",
  MAIN_CONTENT: "Here you can reset your password",
  BUTTON: "Reset Password",
};
export const DASHBOARD = {
  CURRENT_MODULE: "Dashboard",
};
export const PROFILE = {
  CURRENT_MODULE: "Edit Profile",
};
export const APPLICATION = {
  CURRENT_MODULE: "Application",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Detail",
};
export const CHANGE_PASSWORD = {
  CURRENT_MODULE: "Change Password",
};
export const CONTACT_SUPPORT = {
  CURRENT_MODULE: "Contact Support",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Detail",
};
export const USERS = {
  CURRENT_MODULE: "Manage Users",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Manage User Detail",
};
export const AFFILIATE = {
  CURRENT_MODULE: "Affiliates User Earning",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Affiliates User Detail",
};
export const PROMOTIONS = {
  CURRENT_MODULE: "Promotions",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Promotion Detail",
};
export const BANK_REQUEST = {
  CURRENT_MODULE: "Bank Request",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Bank Request Detail",
};
export const TRADES = {
  CURRENT_MODULE: "Manage Trades",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Manage Trades Detail",
};
export const PAYMENTS = {
  CURRENT_MODULE: "Manage Payments",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Manage Payment Detail",
};
export const SYSTMENACCESS = {
  CURRENT_MODULE: "System Access",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "System Access Detail",
};
export const CMS = {
  CURRENT_MODULE: "App Content",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Detail",
};
export const FAQ = {
  CURRENT_MODULE: "FAQS",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Detail",
};
export const REVENUE = {
  CURRENT_MODULE: "Revenue Management",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Revenue Management Detail",
};

export const BLOGS = {
  CURRENT_MODULE: "Blog Management",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Blog Detail",
};

export const JOBS = {
  CURRENT_MODULE: "Job Management",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Job Detail",
};
export const TESTIMONY = {
  CURRENT_MODULE: "Testimony Management",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Testimony Detail",
};
export const FEATURE = {
  CURRENT_MODULE: "Feature Management",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Feature Detail",
};

export const BASICSETTING = {
  CURRENT_MODULE: "Basic Settings",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Basic Detail",
};
export const BACKOFFICE = {
  CURRENT_MODULE: "My Task",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "My Task Detail",
};
export const REPORT = {
  CURRENT_MODULE: "Reports",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Report Detail",
};
export const CONFIGURATION = {
  CURRENT_MODULE: "Automated Email",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Detail",
};
export const LOGS = {
  CURRENT_MODULE: "Manage Logs",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Manage Logs Detail",
};
export const STOCKS = {
  CURRENT_MODULE: "Manage Stocks",
  CURRENT_LIST: "Listing",
  CURRENT_VIEW: "Manage Stock Detail",
};
