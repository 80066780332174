export const LOGIN_LOGO = "/../../../assets/images/logo.png";
export const SIDEBAR_LOGO = "/../../../../assets/img/login_logo.png";
export const PROFILE_IMG = "/../../../assets/img/avatar/avatar-4.png";
export const DUMMY_IMG = "/../../../assets/img/avatar/avatar-4.png";
export const PRODUCT_IMG = "../../../assets/img/news/img14.jpg";
export const MAIN_LOGO = "/../../../assets/images/kliringLogo.png";
export const MAIN_LOGO_DUMMY =
  "/../../../assets/images/faces-clipart/pic-3.png";
export const MAIN_LOGO_DUMMY_ = "/../../../assets/images/logo.png";
export const MAIN_DUMMY_ = "/../../../assets/images/img-icon/dummy.png";
export const MAIN__DUMMY_ = "/../../../assets/images/img-icon/icon.jpg";
export const SIDEBAR_LOGIN_LOGO =
  "/../../../assets/images/sidebar-login-logo.png";
export const IMG_LOGO_1 = "/../../../assets/images/dashboard/img1.png";
export const IMG_LOGO_2 = "/../../../assets/images/dashboard/img2.png";
export const IMG_LOGO_3 = "/../../../assets/images/dashboard/img3.png";
export const DASHBOARD = "/../../../assets/images/icons/home.png";
export const DASHBOARD_USER = "/../../../assets/images/dashboard/user.png";
export const DASHBOARD_PAYMENT =
  "/../../../assets/images/dashboard/payment.png";
export const DASHBOARD_TRADE = "/../../../assets/images/dashboard/trade.png";
