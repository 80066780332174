import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//imported
import ReactPagination from "components/shared/ReactPagination";
import * as Path from "routes/paths";
import { TRADES, PAGE_LIMIT } from "helpers/viewContent";
import TradeModal from "./TradeModal";
import SearchFilter from "components/shared/SearchFilter";
import { tradesDatacsv, tradesSchduleDatacsv } from "helpers/common";
//services
import { getList_ } from "store/services/commonService";
import Exposure from "./Exposure";

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount, loader }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateApi, setisUpdateApi] = useState(false);
  const [csvDataP, setCsvDataP] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [listingSchedule, setListingSchedule] = useState([]);

  const [field, setfields] = useState({
    search_date: new Date(),
  });

  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const [listing, setListing] = useState([]);
  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      const itemClientName = list?.userDetails?.first_name.toLowerCase();
      const itemaccountId = list?.userDetails?.accountId.toLowerCase();
      return (
        itemName?.includes(searchTerm) ||
        itemClientName?.includes(searchTerm) ||
        itemaccountId?.includes(searchTerm)
      );
    });
  }

  const [currentValue, setCurrentValue] = useState(null);
  //show modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCurrentValue(null);
  };
  const handleShow = (list) => {
    setShow(true);
    setCurrentValue(list);
  };

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "getUserTradesWithFilter",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      let arrP = await tradesDatacsv(res?.data?.data);
      setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  //filter
  const [filterS, setFilterS] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const fetchScheduleData = async () => {
    try {
      let query = {
        ...filter,
        url: "getScheduledOrders",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListingSchedule(res?.data?.data);
      let arrP = await tradesSchduleDatacsv(res?.data?.data);
      setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
    fetchScheduleData();
  }, [filter, isUpdateApi, tabIndex]);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
  };
  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  const selectSearchOption = [
    {
      id: "1",
      value: "opened",
      name: "Open Trades",
    },
    {
      id: "2",
      value: "pending",
      name: "Pending Trades",
    },
    {
      id: "3",
      value: "schedule",
      name: "Schedule Trades",
    },
  ];
  //render html
  const renderList_ = () => {
    return (
      listToDisplay?.length > 0 &&
      listToDisplay.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td
            onClick={() =>
              list?.userDetails?.accountId
                ? handleShow(list)
                : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.userDetails?.accountId
                ? list?.userDetails?.accountId
                : "N/A"}
            </b>
          </td>
          <td className="green_status">
            $
            {list?.userDetails?.walletCredit
              ? (list.userDetails?.walletCredit).toFixed(2)
              : "0"}
          </td>
          <td>
            $
            {list?.userDetails?.equity
              ? (list.userDetails?.equity).toFixed(2)
              : "0"}
          </td>
          <td>{list.shortName ? list?.shortName : list.name}</td>
          <td>${list?.price ? (list?.price).toFixed(2) : "0"}</td>
          <td
            onClick={() => renderTradesGraph(list?.symbol)}
            className="cursor-pointer"
            title="View"
          >
            <b>{list?.symbol}</b>
          </td>
          <td>${list?.boughtPrice ? (list?.boughtPrice).toFixed(2) : "0"}</td>
          <td>
            ${list?.current_price ? (list?.current_price).toFixed(2) : "0"}
          </td>
          <td>
            <label
              className={
                list?.unrealized_plpc && list.unrealized_plpc < 0
                  ? "badge badge-danger"
                  : "badge badge-success"
              }
            >
              {list?.unrealized_plpc ? (list?.unrealized_plpc).toFixed(2) : "0"}
            </label>
          </td>
          <td>{list?.qty ? list.qty.toFixed(2) : "0"}</td>
        </tr>
      ))
    );
  };
  //render html schedule
  const renderListS_ = () => {
    return (
      listingSchedule?.length > 0 &&
      listingSchedule.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td
            onClick={() =>
              list?.userDetails?.accountId
                ? handleShow(list)
                : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.userDetails?.accountId
                ? list?.userDetails?.accountId
                : "N/A"}
            </b>
          </td>
          <td className="green_status">
            {list?.commission ? (list?.commission).toFixed(2) : "0"}
          </td>
          <td>${list?.orderPrice ? (list?.orderPrice).toFixed(2) : "0"}</td>
          <td>{list.stockName ? list?.stockName : "N/A"}</td>
          <td>${list?.stockPrice ? (list?.stockPrice).toFixed(2) : "0"}</td>
          <td
            onClick={() => renderTradesGraph(list?.symbol)}
            className="cursor-pointer"
            title="View"
          >
            <b>{list?.symbol}</b>
          </td>
          <td>{list?.scheduledType ? list?.scheduledType : "0"}</td>
          <td>
            <label className="badge badge-success payment_status">
              {list.status}
            </label>
          </td>
          <td>{list.qty ? (list?.qty).toFixed(2) : "0"}</td>
        </tr>
      ))
    );
  };

  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Helmet title={TRADES.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{TRADES.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.trade}>{TRADES.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Unique Trades</Tab>
          <Tab>Overall Exposure</Tab>
        </TabList>{" "}
        <TabPanel>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card graph_card">
                <div className="card-body">
                  <h4 className="card-title">
                    <SearchFilter
                      searchValue={searchValue}
                      handleSearchChange={handleSearchChange}
                      clearSerchFilter={clearSerchFilter}
                      options={selectSearchOption}
                      SearchText="Search by client/stock name"
                      isSearchDate={false}
                      field={field}
                      setfields={setfields}
                      isExportButton={true}
                      isAddButton={false}
                      isSelectBox={true}
                      isInput={true}
                      filter={filter}
                      setFilter={setFilter}
                      isSelectOtherBox={false}
                      dropdownField="status"
                      csvData={csvDataP}
                    />
                  </h4>
                  <div class="table-responsive">
                    <table className="table common_th">
                      {filter.status === "schedule" && (
                        <thead>
                          <tr>
                            <th onClick={() => handleSortingFunc("first_name")}>
                              {" "}
                              Client's Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "first_name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "first_name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("accountId")}>
                              {" "}
                              Account Id{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("commission")}>
                              {" "}
                              Commission{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("orderPrice")}>
                              {" "}
                              Order Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "orderPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "orderPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("name")}>
                              {" "}
                              Stock Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("stockPrice")}>
                              {" "}
                              Stock Price
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "stockPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "stockPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th> Symbol</th>
                            <th
                              onClick={() => handleSortingFunc("scheduledType")}
                            >
                              {" "}
                              Scheduled Type{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "scheduledType"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "scheduledType"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th> Status</th>
                            <th> Qty</th>
                          </tr>
                        </thead>
                      )}
                      {filter.status !== "schedule" && (
                        <thead>
                          <tr>
                            <th onClick={() => handleSortingFunc("user_name")}>
                              {" "}
                              Client's Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "user_name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "user_name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("accountId")}>
                              {" "}
                              Account Id{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th
                              onClick={() => handleSortingFunc("walletCredit")}
                            >
                              {" "}
                              Balance{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "walletCredit"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "walletCredit"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("equity")}>
                              {" "}
                              Equity{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "equity"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "equity"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("name")}>
                              {" "}
                              Stock Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("price")}>
                              {" "}
                              Price
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "price"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "price"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th> Symbol</th>
                            <th
                              onClick={() => handleSortingFunc("boughtPrice")}
                            >
                              {" "}
                              Bought Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "boughtPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "boughtPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th
                              onClick={() => handleSortingFunc("current_price")}
                            >
                              {" "}
                              Current Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "current_price"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "current_price"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th> Open PN</th>
                            <th> Qty</th>
                          </tr>
                        </thead>
                      )}
                      {filter.status === "schedule" && (
                        <tbody>{renderListS_()}</tbody>
                      )}
                      {filter.status !== "schedule" && (
                        <tbody>{renderList_()}</tbody>
                      )}
                    </table>
                  </div>{" "}
                  {filter.status !== "schedule" && (
                    <>
                      {listing.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={PAGE_LIMIT}
                            totalItemsCount={totalItemsCount}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {listing.length == 0 && (
                        <div className="row">
                          <div className="col-md-6 pt-5">
                            <span>No data found...</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {filter.status === "schedule" && (
                    <>
                      {listingSchedule?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={PAGE_LIMIT}
                            totalItemsCount={totalItemsCount}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {listingSchedule?.length == 0 && (
                        <div className="row">
                          <div className="col-md-6 pt-5">
                            <span>No data found...</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <Exposure />
        </TabPanel>
      </Tabs>
      <TradeModal
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        dispatch={dispatch}
        setisUpdateApi={setisUpdateApi}
        filterType={filter?.status}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
