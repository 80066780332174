import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//imported
import ReactPagination from "components/shared/ReactPagination";
import { USERS, itemsPerPage, PAGE_LIMIT } from "helpers/viewContent";
import {
  DATE_FORMAT_TIME,
  FORMAT_TEXT_VALUE,
  DATE_FORMAT_TIME_,
} from "helpers/common";
import { MAIN_LOGO_DUMMY_ } from "utils/constant";
import SendMessageModal from "./SendMessageModal";
import AddressModal from "./AddressModal";
import DocumentsModal from "./DocumentsModal";
import AssignMembers from "./AssignMembers";
import Commission from "./Commission";
import BalanceOperation from "./BalanceOperation";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { SIDEBAR_LOGIN_LOGO } from "utils/constant";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import InfoModal from "./InfoModal";
import TradeModalInfo from "./TradeModalInfo";

import ProfileEdit from "./ProfileEdit";
//services
import {
  getDetail_,
  updateData_,
  getList_,
} from "store/services/commonService";
import BackofficeComment from "./BackofficeComment";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  loader,
  match,
  user,
  userProfile,
}) => {
  const history = useHistory();
  let editkey = match.params.id;
  let userRole = user?.user ? user?.user : userProfile;
  const [userPermissions, setUserPermissions] = useState({});

  //pagination
  const [activePage, setActivePage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndex2, setTabIndex2] = useState(0);
  const [editData, setEditData] = useState({});

  //info modal finance
  const [currentInfoValue, setCurrentInfoValue] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleInfoClose = () => {
    setShowInfoModal(false);
    setCurrentInfoValue(null);
  };
  const handleInfoShow = (list) => {
    setShowInfoModal(true);
    setCurrentInfoValue(list);
  };

  //trading info modal
  const [currentTradeValue, setCurrentTradeValue] = useState(null);
  const [showTradeModal, setShowTradeModal] = useState(false);
  const handleTradeClose = () => {
    setShowTradeModal(false);
    setCurrentTradeValue(null);
  };
  const handleTradeShow = (list) => {
    setShowTradeModal(true);
    setCurrentTradeValue(list);
  };

  //show modal message
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //show modal addresss
  const [showAddress, setShowAddress] = useState(false);
  const handleAddressClose = () => setShowAddress(false);
  const handleAddressShow = () => setShowAddress(true);
  const [isUpdateProfile, setisUpdateProfile] = useState(false);

  //edit profile modal
  const [editProfileModal, setEditProfileModal] = useState(false);
  const handleEditProfileClose = () => setEditProfileModal(false);
  const handleEditProfileShow = () => setEditProfileModal(true);

  //show modal documents
  const [showDocuments, setShowDocuments] = useState(false);
  const handleDocumentsClose = () => setShowDocuments(false);
  const handleDocumentsShow = () => setShowDocuments(true);

  //show assign modal message
  const [assignMember, setAssignMember] = useState(false);
  const handleAssignClose = () => setAssignMember(false);
  const handleAssignShow = () => setAssignMember(true);

  //show balance operation modal
  const [operation, setOperation] = useState(false);
  const handleOperationClose = () => setOperation(false);
  const handleOperationShow = () => setOperation(true);
  const data = [
    {
      symbol: `Group inc.`,
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
  ];
  const [listing, setListing] = useState(data);
  const [tradesListing, setTradesListing] = useState([]);
  const [totalCountTrade, settotalCountTrade] = useState(0);

  const [activities, setActivities] = useState([]);
  const [finances, setfinances] = useState([]);
  const [financesCount, setfinancesCount] = useState(0);

  async function getDataList() {
    try {
      let query = {
        url: "getUserById",
        id: editkey,
      };
      setisUpdateProfile(false);
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getDetail_(query));
      setEditData(res?.data);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (editkey) {
      getDataList();
      getTradesInfoDetail();
      getActivitiesData();
    }
  }, [activePage, tabIndex, isUpdateProfile]);

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    activities?.tradeActivity?.length > 0 &&
    activities?.tradeActivity?.slice(indexOfFirstItem, indexOfLastItem);

  const currentNoneTradeItems =
    activities?.nonTradeActivity?.length > 0 &&
    activities?.nonTradeActivity?.slice(indexOfFirstItem, indexOfLastItem);
  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  //verify status setting data
  const VerifyStatusFunc = async (status, type) => {
    try {
      let obj = {
        url: "editUser",
        userId: editData?._id,
      };
      if (type === "personal_info") {
        obj.personal_info = status;
      } else if (type === "profile_info") {
        obj.profile_info = status;
      } else if (type === "documents") {
        obj.documents = status;
      } else if (type === "biometric") {
        obj.biometric = status;
      } else if (type === "employment") {
        obj.employment = status;
      }
      await dispatch(updateData_(obj));
      getDataList();
    } catch (err) {
      console.log(err);
    }
  };

  //verify trades setting data toogle buttons
  const VerifyStatusTradingFunc = async (name) => {
    try {
      let obj = {
        url: "editUser",
        userId: editData?._id,
      };
      if (name === "isTrade") {
        obj.isTrade = !editData?.isTrade;
      }
      if (name === "isDeposit") {
        obj.isDeposit = !editData?.isDeposit;
      }
      if (name === "isWithdraw") {
        obj.isWithdraw = !editData?.isWithdraw;
      }
      if (name === "isClosed") {
        obj.isClosed = !editData?.isClosed;
      }
      await dispatch(updateData_(obj));
      getDataList();
    } catch (err) {
      console.log(err);
    }
  };

  //verify user aprroved alpaca status
  const VerifyAlpacaStatusFunc = async (status) => {
    try {
      let obj = {
        url: "update/status",
        userId: editData?._id,
        status: status,
      };
      await dispatch(updateData_(obj));
      getDataList();
    } catch (err) {
      console.log(err);
    }
  };

  async function getActivitiesData() {
    try {
      let query = {
        url: "account/activities",
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getDetail_(query));
      setActivities(res?.data);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const [filter, setFilter] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });

  //trades info
  async function getTradesInfoDetail() {
    try {
      let query = {
        ...filter,
        url: "getUserTradesWithFilter",
        userId: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setTradesListing(res?.data?.data);
      settotalCountTrade(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //pagination
  const handlePageChangeTrade = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  //payment info finances
  const [filterT, setFilterT] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });

  const fetchData = async () => {
    try {
      let query = {
        ...filterT,
        url: "getUserTransactionsWithFilter",
        type: ["withdrawl", "incoming"],
        isAdmin: true,
        userId: editkey,
      };
      let res = await dispatch(getList_(query));
      setfinances(res?.data?.data);
      setfinancesCount(res?.data?.totalcount);
    } catch (err) {
      console.log(err);
    }
  };
  const handlePageChangeT = (page) => {
    setFilterT({
      ...filterT,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (editkey) {
      // getTradesInfoDetail();
      fetchData();
    }
  }, [tabIndex, filter, filterT]);

  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    let userPermission =
      userProfile && userProfile?.permissions?.length > 0
        ? JSON.parse(userProfile?.permissions)
        : {};
    let userData =
      user?.user?.permissions && user?.user?.permissions?.length > 0
        ? JSON.parse(user?.user?.permissions)
        : userPermission;
    setUserPermissions(userData);
  }, [userRole]);

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilterT({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilterT({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };
  const handleSortingFuncT = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  return (
    <>
      {/* <Helmet title={USERS.CURRENT_MODULE} /> */}
      <div className="page-header">
        <h3
          className="page-title"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(Path.users)}
        >
          <i className={`cursor-pointer mdi mdi-arrow-left`} />
          Back
        </h3>
        {/* {USERS.CURRENT_VIEW} */}
        <nav aria-label="breadcrumb">
          {/* <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.users}>{USERS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Detail
            </li>
          </ol> */}
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-4 grid-margin stretch-card profile_sider">
          <div className="card graph_card border_custom">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="card-title">Profile</h4>
                {editData?.status === "pending" ||
                editData?.status === "rejected" ? (
                  <button
                    type="submit"
                    className="btn btn-sm btn-gradient-success"
                    title="Approve"
                    onClick={() => VerifyAlpacaStatusFunc("approved")}
                  >
                    Approve
                  </button>
                ) : editData?.status === "approved" ? (
                  <label className="badge badge-success">
                    {FORMAT_TEXT_VALUE(editData?.status)}
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <img
                    src={
                      editData?.profileImage
                        ? editData?.profileImage
                        : MAIN_LOGO_DUMMY_
                    }
                    className="mb-2 rounded img-view user-profileimage"
                    alt="image"
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.first_name
                      ? `${editData?.first_name} ${editData?.last_name}`
                      : "N/A"}
                  </label>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <p>Registration Date</p>
                  <label className="col-sm-12 col-form-label">Status</label>
                </div>
                <div className="col-md-6">
                  <p>{DATE_FORMAT_TIME_(editData?.createdAt)}</p>
                  <label className="col-sm-12 col-form-label">
                    {editData?.status
                      ? FORMAT_TEXT_VALUE(editData?.status)
                      : "N/A"}
                  </label>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6">
                  <p>Send on email : </p>
                </div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-sm btn-gradient-primary button_red"
                    title="Send Message"
                    onClick={handleShow}
                  >
                    Send Message
                  </button>
                </div>
              </div> */}
              {/* {editData?.role==='ADMIN' && ( */}
              {editData?.assignDetails?.name && (
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p>Assign Member : </p>
                  </div>
                  {/* {editData?.assignId !== null && */}
                  <div className="col-md-6">
                    <h6>
                      <b>{editData?.assignDetails?.name}</b>
                    </h6>
                    {/* <button
                      type="button"
                      className="btn btn-sm btn-gradient-primary button_red"
                      title="Assign Member"
                      onClick={
                        userRole?.role === "SUBADMIN" &&
                        userPermissions.hasOwnProperty("userCount") &&
                        userPermissions?.userCount?.update
                          ? handleAssignShow
                          : userRole?.role === "ADMIN"
                          ? handleAssignShow
                          : console.log("#")
                      }
                    >
                      {editData?.assignId ? "Ressign" : "Assign"} Member
                    </button> */}
                  </div>
                  {/* } */}
                  {/* <div className="col-md-6">
                  </div> */}
                </div>
              )}

              {/* )} */}
              {/* {user?.role === "ADMIN" && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Balance Opreation: </p>
                    </div>
                    <div className="col-md-6">
                      <button
                        type="button"
                        disabled={editData?.accountId === null ? true : false}
                        className="btn btn-sm btn-gradient-primary button_red"
                        title={
                          editData?.accountId === null
                            ? "Account id is null"
                            : "Pay"
                        }
                        onClick={() => handleOperationShow()}
                      >
                        {" "}
                        Pay{" "}
                      </button>
                    </div>
                  </div>
                </>
              )} */}
              <hr />
              {/* <div className="d-flex align-items-center justify-content-between">
                <h5>Personal Information</h5>
                <div className="p-0 d-flex align-items-center justify-content-end">
                  <button
                    type="button"
                    className="edit_card_cta btn btn-sm btn-gradient-primary"
                    title="Edit"
                    onClick={
                      userRole?.role === "SUBADMIN" &&
                      userPermissions.hasOwnProperty("userCount") &&
                      userPermissions?.userCount?.update
                        ? handleEditProfileShow
                        : userRole?.role === "ADMIN"
                        ? handleEditProfileShow
                        : console.log("#")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_252_1049)">
                        <path
                          d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_252_1049">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  {editData?.personal_info ? (
                    <label className="badge badge-success">Passed</label>
                  ) : (
                    <button
                      type="submit"
                      onClick={() =>
                        userRole?.role === "SUBADMIN" &&
                        userPermissions.hasOwnProperty("userCount") &&
                        userPermissions?.userCount?.update
                          ? VerifyStatusFunc(true, "personal_info")
                          : userRole?.role === "ADMIN"
                          ? VerifyStatusFunc(true, "personal_info")
                          : console.log("#")
                      }
                      className="btn btn-sm btn-gradient-primary"
                      title="Incomplete"
                    >
                      Incomplete
                    </button>
                  )}
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">First Name</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.first_name ? `${editData?.first_name}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Last Name</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.last_name ? `${editData?.last_name}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Given Name</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.given_name ? `${editData?.given_name}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Email</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.email ? editData?.email : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Mobile Number
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.mobileNumber
                      ? `${editData?.countryCode} ${editData?.mobileNumber}`
                      : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="">Wallet</label>
                </div>
                <div className="col-md-6">
                  <label>
                    {editData?.walletCredit != null &&
                    !isNaN(editData?.walletCredit)
                      ? "$" + parseInt(editData?.walletCredit).toFixed(2)
                      : "$0"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Nationality
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.nationality ? `${editData?.nationality}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Date of Birth
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.dob ? `${editData?.dob}` : "N/A"}
                  </label>
                </div>
              </div>
              <hr />
              <div className="">
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Profile Info</h5>
                  <div className="p-0 d-flex align-items-center justify-content-end">
                    <button
                      type="button"
                      className="edit_card_cta btn btn-sm btn-gradient-primary"
                      title="Edit"
                      onClick={
                        userRole?.role === "SUBADMIN" &&
                        userPermissions.hasOwnProperty("userCount") &&
                        userPermissions?.userCount?.update
                          ? handleAddressShow
                          : userRole?.role === "ADMIN"
                          ? handleAddressShow
                          : console.log("#")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_252_1049)">
                          <path
                            d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_252_1049">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    {editData?.profile_info ? (
                      <label class="badge badge-success">Passed</label>
                    ) : (
                      <button
                        type="submit"
                        onClick={() =>
                          userRole?.role === "SUBADMIN" &&
                          userPermissions.hasOwnProperty("userCount") &&
                          userPermissions?.userCount?.update
                            ? VerifyStatusFunc(true, "profile_info")
                            : userRole?.role === "ADMIN"
                            ? VerifyStatusFunc(true, "profile_info")
                            : console.log("#")
                        }
                        className="btn btn-sm btn-gradient-primary"
                        title="Incomplete"
                      >
                        Incomplete
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <h5>Address</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="col-sm-12 col-form-label">
                    {editData?.location ? editData?.location : "N/A"}
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="col-lg-8 grid-margin stretch-card">
          <div className="card graph_card border_custom">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <p className="float-right">
                  Current Balance : $
                  {editData?.walletCredit != null &&
                  !isNaN(editData?.walletCredit)
                    ? parseFloat(editData?.walletCredit).toFixed(2)
                    : "0"}
                </p>
                <div className="p-0 d-flex align-items-center justify-content-end">
                  <p className="float-right">
                    Deposit Transaction : + $
                    {editData?.depositTotal ? editData?.depositTotal : "0"}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="float-right">
                  Current Equity : $
                  {editData?.equity != null && !isNaN(editData?.equity)
                    ? parseFloat(editData?.equity).toFixed(2)
                    : "0"}
                </p>
                <div className="p-0 d-flex align-items-center justify-content-end">
                  <p className="float-right">
                    Withdrawal Transaction : - $
                    {editData?.withdrawalTotal != null &&
                    !isNaN(editData?.withdrawalTotal)
                      ? parseFloat(editData?.withdrawalTotal).toFixed(2)
                      : "0"}
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className="float-right">
                  Trades Count :{" "}
                  {editData?.tradeCount ? editData?.tradeCount : "0"}
                </p>
                <div className="p-0 d-flex align-items-center justify-content-end">
                  <p className="float-right">
                    Trades Sum : $
                    {editData?.openPositionValue != null &&
                    !isNaN(editData?.openPositionValue)
                      ? parseFloat(editData?.openPositionValue).toFixed(2)
                      : "0"}
                  </p>
                </div>
              </div>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>Finances</Tab>
                  {/* <Tab>Trades</Tab> */}
                  {/* <Tab>Account Activity</Tab> */}
                </TabList>
                <TabPanel>
                  <h4 className="card-title">Finances Detail</h4>
                  <div class="table-responsive fixed_height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            onClick={() => handleSortingFunc("transactionId")}
                          >
                            {""}
                            Transaction Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          {/* <th
                            onClick={() => handleSortingFunc("transactionType")}
                          >
                            {" "}
                            Transaction Type
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionType"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionType"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th> */}
                          <th onClick={() => handleSortingFunc("amount")}>
                            {" "}
                            Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th> Type </th>
                          <th onClick={() => handleSortingFunc("status")}>
                            {" "}
                            Status{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {finances?.length > 0 &&
                          finances?.map((list, i) => {
                            return (
                              <tr
                                key={i}
                                onClick={() => handleInfoShow(list)}
                                className="cursor-pointer"
                                title="View"
                              >
                                <td>
                                  {list?.userDetails?.accountData
                                    ?.account_number
                                    ? list?.userDetails?.accountData
                                        ?.account_number
                                    : "N/A"}
                                </td>
                                {/* <td>{list?.transactionType}</td> */}
                                <td>
                                  ${list?.amount ? list.amount.toFixed(2) : "0"}
                                </td>

                                <td>
                                  {list?.type === "outgoing"
                                    ? "withdrawal"
                                    : "deposit"}
                                </td>
                                <td>
                                  <label className="badge badge-success">
                                    {FORMAT_TEXT_VALUE(list?.status)}
                                  </label>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    {finances?.length == 0 && (
                      <span className="py-2 spanclass">No data found...</span>
                    )}
                  </div>
                  {finances.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filterT?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={financesCount}
                        handlePageChange={handlePageChangeT}
                      />
                    </div>
                  )}
                </TabPanel>

                <TabPanel>
                  <h4 className="card-title">Trades Detail</h4>
                  <div class="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th> Trade </th>
                          <th> Symbol </th>
                          <th onClick={() => handleSortingFuncT("qty")}>
                            {" "}
                            Qty.{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "qty"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "qty"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th onClick={() => handleSortingFuncT("price")}>
                            {" "}
                            Price{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "price"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "price"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th onClick={() => handleSortingFuncT("status")}>
                            {" "}
                            Status{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFuncT("unrealized_plpc")
                            }
                          >
                            {" "}
                            PLPC{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "unrealized_plpc"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "unrealized_plpc"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th
                            onClick={() => handleSortingFuncT("qty_available")}
                          >
                            {" "}
                            Qty Available{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "qty_available"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "qty_available"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th onClick={() => handleSortingFuncT("boughtPrice")}>
                            {" "}
                            Bought Price{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "boughtPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "boughtPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th
                            onClick={() => handleSortingFuncT("current_price")}
                          >
                            {" "}
                            Current Price{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "current_price"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "current_price"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tradesListing?.length > 0 &&
                          tradesListing.map((list, i) => {
                            return (
                              <tr
                                key={i}
                                onClick={() => handleTradeShow(list)}
                                className="cursor-pointer"
                                title="View"
                              >
                                <td className="d-flex align-items-center">
                                  <div className="symbol_img me-2">
                                    <img
                                      src={
                                        list?.imgUrl
                                          ? list?.imgUrl
                                          : SIDEBAR_LOGIN_LOGO
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  {list?.shortName
                                    ? list?.shortName
                                    : list?.name}
                                </td>
                                <td
                                  onClick={() =>
                                    renderTradesGraph(list?.symbol)
                                  }
                                  className="cursor-pointer"
                                  title="View"
                                >
                                  <b>{list?.symbol}</b>
                                </td>
                                <td>{list.qty ? list.qty.toFixed(2) : "0"}</td>
                                <td>
                                  ${list.price ? list.price.toFixed(2) : "0"}
                                </td>
                                <td>
                                  <label className="badge badge-success payment_status">
                                    {FORMAT_TEXT_VALUE(list.status)}
                                  </label>
                                </td>
                                <td>
                                  $
                                  {list?.unrealized_plpc
                                    ? (list?.unrealized_plpc).toFixed(2)
                                    : "0"}
                                </td>
                                <td>
                                  {list.qty_available
                                    ? list.qty_available.toFixed(2)
                                    : "0"}
                                </td>
                                <td>
                                  $
                                  {list.boughtPrice
                                    ? list.boughtPrice.toFixed(2)
                                    : "0"}
                                </td>
                                <td>
                                  $
                                  {list.current_price
                                    ? list.current_price.toFixed(2)
                                    : "0"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {tradesListing?.length == 0 && (
                      <span className="py-2 spanclass">No data found...</span>
                    )}
                  </div>
                  {tradesListing.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={totalCountTrade}
                        handlePageChange={handlePageChangeTrade}
                      />
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <Tabs
                    selectedIndex={tabIndex2}
                    onSelect={(index) => setTabIndex2(index)}
                  >
                    <TabList>
                      <Tab>Trading Activity</Tab>
                      <Tab>Non Trading Activity</Tab>
                    </TabList>
                    <TabPanel>
                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Account ID</th>
                              <th> Transaction Time</th>
                              <th> Type</th>
                              <th> Order Status</th>
                              <th>Price</th>
                              <th>Qty</th>
                              <th>Side</th>
                              {/* <th onClick={() => handleSortingFunc("type")}>
                                {" "}
                                Type{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "type"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "type"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() =>
                                  handleSortingFunc("order_status")
                                }
                              >
                                {" "}
                                Order Status
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "order_status"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "order_status"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th onClick={() => handleSortingFunc("price")}>
                                {" "}
                                Price
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "price"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "price"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th onClick={() => handleSortingFunc("qty")}>
                                {" "}
                                Qty{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "qty"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "qty"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th onClick={() => handleSortingFunc("side")}>
                                {" "}
                                Side{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "side"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "side"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th> */}
                              <th> Symbol </th>
                              <th> Order Id </th>
                            </tr>
                          </thead>
                          {currentItems?.length > 0 && (
                            <tbody>
                              {currentItems?.length > 0 &&
                                currentItems?.map((list, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {list.account_id
                                          ? list.account_id
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {DATE_FORMAT_TIME(
                                          list.transaction_time
                                        )}
                                      </td>
                                      <td>{list.type}</td>
                                      <td>
                                        <label className="badge badge-success payment_status">
                                          {list.order_status}
                                        </label>
                                      </td>
                                      <td>
                                        {list.price != null &&
                                        !isNaN(list.price)
                                          ? "$" +
                                            parseInt(`${list.price}`).toFixed(2)
                                          : "$0"}
                                      </td>
                                      <td>{list.qty}</td>
                                      <td>{list.side}</td>
                                      <td
                                        onClick={() =>
                                          renderTradesGraph(list?.symbol)
                                        }
                                        className="cursor-pointer"
                                        title="View"
                                      >
                                        <b>{list?.symbol}</b>
                                      </td>
                                      <td>{list.order_id}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                      </div>
                      {activities?.tradeActivity?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={activePage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={activities?.tradeActivity?.length}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {activities?.tradeActivity?.length == 0 && (
                        <span className="py-2 spanclass">No data found...</span>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Account Id</th>
                              <th> Activity Type </th>
                              <th> Date</th>
                              <th> Net Amount </th>
                              <th> Status </th>
                              <th> Description </th>
                            </tr>
                          </thead>
                          {currentNoneTradeItems?.length > 0 && (
                            <tbody>
                              {currentNoneTradeItems?.length > 0 &&
                                currentNoneTradeItems?.map((list, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {list.account_id
                                          ? list.account_id
                                          : "N/A"}
                                      </td>
                                      <td>{list.date}</td>
                                      <td>
                                        {DATE_FORMAT_TIME(
                                          list.transaction_time
                                        )}
                                      </td>
                                      <td>
                                        $
                                        {list.net_amount
                                          ? list.net_amount
                                          : "$0"}
                                      </td>
                                      <td>
                                        <label className="badge badge-success payment_status">
                                          {list.status}
                                        </label>
                                      </td>
                                      <td>
                                        {list.description
                                          ? list.description
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                      </div>
                      {activities?.nonTradeActivity?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={activePage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={
                              activities?.nonTradeActivity?.length
                            }
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {activities?.nonTradeActivity?.length == 0 && (
                        <span className="py-2 spanclass">No data found...</span>
                      )}
                    </TabPanel>
                  </Tabs>
                </TabPanel>
              </Tabs>
              {/* {listing.length > 0 && (
                            <div className="pagination-box-review">
                                <ReactPagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    handlePageChange={handlePageChange}
                                />
                            </div>
                            )} */}
              {listing.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="col-lg-4 grid-margin">
          <div className="card graph_card border_custom">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5> Documents </h5>
                <div className="p-0 d-flex align-items-center justify-content-end">
                  {editData?.documents ? (
                    <label class="badge badge-success">Passed</label>
                  ) : (
                    <button
                      type="submit"
                      onClick={() =>
                        userRole?.role === "SUBADMIN" &&
                        userPermissions.hasOwnProperty("userCount") &&
                        userPermissions?.userCount?.update
                          ? VerifyStatusFunc(true, "documents")
                          : userRole?.role === "ADMIN"
                          ? VerifyStatusFunc(true, "documents")
                          : console.log("#")
                      }
                      className="btn btn-sm btn-gradient-primary"
                      title="Incomplete"
                    >
                      Incomplete
                    </button>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="p-0 d-flex align-items-center justify-content-end">
                  <label className=" col-form-label">
                    <a href={editData?.id_photo} target="_blank">
                      Passport
                    </a>
                  </label>
                </div>
                <div className="col-md-2">
                  <button
                    type="button"
                    className="edit_card_cta btn btn-sm btn-gradient-primary"
                    title="Edit"
                    onClick={
                      userRole?.role === "SUBADMIN" &&
                      userPermissions.hasOwnProperty("userCount") &&
                      userPermissions?.userCount?.update
                        ? handleDocumentsShow
                        : userRole?.role === "ADMIN"
                        ? handleDocumentsShow
                        : console.log("#")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_252_1049)">
                        <path
                          d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_252_1049">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">ID Type</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.id_type ? `${editData?.id_type}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">City</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.city ? `${editData?.city}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Unit</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.unit ? `${editData?.unit}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Country</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.country ? `${editData?.country}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Address Information
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.address_information
                      ? `${editData?.address_information}`
                      : "N/A"}
                  </label>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="grid-margin stretch-card">
                  <div className="card">
                    <div className="">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Biometric</h5>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          {editData?.biometric ? (
                            <label class="badge badge-success">Passed</label>
                          ) : (
                            <button
                              type="submit"
                              onClick={() =>
                                userRole?.role === "SUBADMIN" &&
                                userPermissions.hasOwnProperty("userCount") &&
                                userPermissions?.userCount.hasOwnProperty(
                                  "update"
                                )
                                  ? VerifyStatusFunc(true, "biometric")
                                  : userRole?.role === "ADMIN"
                                  ? VerifyStatusFunc(true, "biometric")
                                  : console.log("#")
                              }
                              className="btn btn-sm btn-gradient-primary"
                              title="Incomplete"
                            >
                              Incomplete
                            </button>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Employment</h5>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          {editData?.employment ? (
                            <label class="badge badge-success">Passed</label>
                          ) : (
                            <button
                              type="submit"
                              onClick={() =>
                                userRole?.role === "SUBADMIN" &&
                                userPermissions.hasOwnProperty("userCount") &&
                                userPermissions?.userCount.hasOwnProperty(
                                  "update"
                                )
                                  ? VerifyStatusFunc(true, "employment")
                                  : userRole?.role === "ADMIN"
                                  ? VerifyStatusFunc(true, "employment")
                                  : console.log("#")
                              }
                              className="btn btn-sm btn-gradient-primary"
                              title="Incomplete"
                            >
                              Incomplete
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Activities
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.activities
                              ? `${editData?.activities}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Employed
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.employed
                              ? `${editData?.employed}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Job Title
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.job_title
                              ? `${editData?.job_title}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Company's Name
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.company_name
                              ? `${editData?.company_name}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Company's Address
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.company_address
                              ? `${editData?.company_address}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Origin of Money
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.origin_of_money
                              ? `${editData?.origin_of_money}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Liquid Assets
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.liquid_assets
                              ? `${editData?.liquid_assets}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Is Trade</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            checked={editData?.isTrade}
                            name="isTrade"
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isTrade")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isTrade")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Is Deposit</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            name="isDeposit"
                            checked={editData?.isDeposit}
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isDeposit")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isDeposit")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Is Withdrawal</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            name="isWithdraw"
                            checked={editData?.isWithdraw}
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isWithdraw")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isWithdraw")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Closed Only</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            name="isClosed"
                            checked={editData?.isClosed}
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isClosed")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isClosed")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <Commission
                        setisUpdateProfile={setisUpdateProfile}
                        editData={editData}
                        dispatch={dispatch}
                        userPermissions={userPermissions}
                        userRole={userRole}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <BackofficeComment
          dispatch={dispatch}
          editkey={editkey}
          setisUpdateProfile={setisUpdateProfile}
          editData={editData}
        /> */}
      </div>

      <SendMessageModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        dispatch={dispatch}
        setShow={setShow}
        editData={editData}
        setisUpdateProfile={setisUpdateProfile}
      />
      <AddressModal
        showAddress={showAddress}
        handleAddressClose={handleAddressClose}
        handleAddressShow={handleAddressShow}
        editData={editData}
        dispatch={dispatch}
        setShowAddress={setShowAddress}
        setisUpdateProfile={setisUpdateProfile}
      />
      <DocumentsModal
        showDocuments={showDocuments}
        handleDocumentsClose={handleDocumentsClose}
        handleDocumentsShow={handleDocumentsShow}
        editData={editData}
        dispatch={dispatch}
        setisUpdateProfile={setisUpdateProfile}
        setShowDocuments={setShowDocuments}
      />
      <ProfileEdit
        editProfileModal={editProfileModal}
        handleEditProfileClose={handleEditProfileClose}
        handleEditProfileShow={handleEditProfileShow}
        editData={editData}
        dispatch={dispatch}
        setEditProfileModal={setEditProfileModal}
        setisUpdateProfile={setisUpdateProfile}
      />
      <AssignMembers
        assignMember={assignMember}
        handleAssignClose={handleAssignClose}
        handleAddressShow={handleAddressShow}
        dispatch={dispatch}
        setAssignMember={setAssignMember}
        editData={editData}
        setisUpdateProfile={setisUpdateProfile}
      />
      <BalanceOperation
        operation={operation}
        handleOperationClose={handleOperationClose}
        handleOperationShow={handleOperationShow}
        dispatch={dispatch}
        setOperation={setOperation}
        editData={editData}
        setisUpdateProfile={setisUpdateProfile}
      />
      <InfoModal
        showInfoModal={showInfoModal}
        handleInfoClose={handleInfoClose}
        // handleInfoShow={handleInfoShow}
        currentInfoValue={currentInfoValue}
        setCurrentInfoValue={setCurrentInfoValue}
      />
      <TradeModalInfo
        showTradeModal={showTradeModal}
        handleTradeClose={handleTradeClose}
        // handleTradeShow={handleTradeShow}
        currentTradeValue={currentTradeValue}
        setCurrentTradeValue={setCurrentTradeValue}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
